import React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "../../ui";

const AboutUs = () => {
  const abouData = [
    {
      icon: "aboutIcon1",
      title: "Commitment to Reliability",
      discription:
        "We understand the importance of your deliveries, which is why we guarantee reliable and timely service, ensuring your packages reach their destination without any hassle.",
    },
    {
      icon: "aboutIcon2",
      title: "Expanding Reach",
      discription:
        "Our extensive network covers a wide range of locations, enabling us to deliver your packages quickly and efficiently, no matter where they need to go.",
    },
    {
      icon: "aboutIcon3",
      title: "Customer-Centric Approach",
      discription:
        "Your satisfaction is our priority. We strive to provide exceptional customer service, offering support every step of the way to ensure your delivery experience is seamless.",
    },
    {
      icon: "aboutIcon4",
      title: "Advanced Tracking Technology",
      discription:
        "Stay informed about your package’s journey with our state-of-the-art tracking system, allowing you to monitor your delivery from pick-up to drop-off.",
    },
    {
      icon: "aboutIcon5",
      title: "Sustainable Practices",
      discription:
        "We are committed to reducing our environmental impact by adopting eco-friendly practices in our operations, from fuel-efficient vehicles to sustainable packaging solutions.",
    },
    {
      icon: "aboutIcon6",
      title: "Comprehensive Coverage",
      discription:
        "Our extensive network spans across multiple regions, allowing us to deliver to even the most remote locations. Whether local or international, we’ve got you covered wherever you need your package to go.",
    },
  ];
  return (
    <>
      <div className="container">
        <div className="comman_space">
          <div className="row justify-content-center my-2 pt-5">
            <div className="col-lg-6 col-md-10 text-center pt-3">
              <div className="commantext">
                <h4>About Us</h4>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-7 order-lg-1 order-2">
              <div className="commanOther">
                {/* <h4>Who we are</h4> */}
                <p>
                  At Swiftgofer , we understand
                  that every package we handle carries more than just items—it
                  carries trust, commitment, and reliability. With years of
                  experience in the logistics industry, we've built a reputation
                  for delivering excellence, ensuring that your shipments reach
                  their destinations safely and on time. Our mission is to
                  provide a seamless and efficient delivery experience that
                  meets the unique needs of each of our customers.
                </p>
                <p>
                  Our expansive network allows us to serve a diverse range of
                  locations, both locally and globally. Whether you’re sending a
                  small parcel or a bulk shipment, we have the resources and
                  expertise to handle it all. We continuously invest in advanced
                  technology and logistics solutions to optimize our delivery
                  processes, ensuring speed and accuracy at every step. Our
                  commitment to innovation allows us to stay ahead in an
                  ever-evolving industry, offering you the best in courier
                  services.
                </p>
              </div>
            </div>
            <div className="col-lg-5 order-lg-2 order-1 text-center">
              <Icon name="aboutusPage" className="img-fluid" />
            </div>
          </div>
          <div className="about_detailmain">
            {abouData?.map((item, index) => {
              return (
                <div className="about_detail" key={index}>
                  <Icon name={item?.icon} className="img-fluid" />
                  <div>
                    <h4>{item?.title}</h4>
                    <p>{item?.discription}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
