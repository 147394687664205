import React from "react";
import { IoWarningOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import { Icon } from "../../ui";

const Detail = () => {
  const location = useLocation();
  const { data } = location.state || {};
  console.log(data?.data?.length, "-------------");

  const deliveryDetails = [
    { label: "Sender Name", value: data?.data?.company_name },
    { label: "Tracking ID", value: data?.data?.tracking_id },
    {
      label: "Dispatch Date",
      value: new Date(data?.data?.recive_date).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
    },
    { label: "Sender Address", value: data?.data?.f_address },
  ];

  const otherDetails = [
    { label: "Receiver Name", value: data?.data?.cust_name },
    { label: "To Address", value: data?.data?.to_address },
    {
      label: "Delivery Date",
      value: new Date(data?.data?.deli_date).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
    },
    // {
    //   label: "Updated At",
    //   value: new Date(data?.data?.updated_at).toLocaleDateString("en-GB", {
    //     day: "2-digit",
    //     month: "2-digit",
    //     year: "numeric",
    //   }),
    // },
    // {
    //   label: "Created At",
    //   value: new Date(data?.data?.created_at).toLocaleDateString("en-GB", {
    //     day: "2-digit",
    //     month: "2-digit",
    //     year: "numeric",
    //   }),
    // },
  ];

  return (
    <>
      <div className="container">
        <div className="comman_space">
          <div className="row justify-content-center my-2 pt-5">
            <div className="col-lg-6 col-md-10 text-center pt-3">
              <div className="commantext">
                <h4>
                  Order
                  <span> Details</span>
                </h4>
                {/* <p>
                  <b>Prime Enterprise</b>
                </p> */}
              </div>
            </div>
          </div>

          <div>
            {data?.status === true ? (
              <div className="order_main">
                <div className="detailcard">
                  <h6 className="detail_heading">Delivery Detail</h6>
                  <div>
                    {deliveryDetails?.map((detail, index) => (
                      <div key={index} className="order_sub">
                        <h6>{detail.label}</h6>
                        <p>{detail.value}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="detailcard">
                  <h6 className="detail_heading">Other Detail</h6>
                  <div>
                    {otherDetails?.map((detail, index) => (
                      <div key={index} className="order_sub">
                        <h6>{detail.label}</h6>
                        <p>{detail.value}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center notFound_sec">
                <Icon name="notfound" className="img-fluid" />
                <div className="message_size py-4">
                  Order Details not found!
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Detail;
