import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="container">
        <div className="comman_space">
          <div className="row justify-content-center my-2 pt-5">
            <div className="col-lg-8 col-md-10 text-center pt-3">
              <div className="commantext">
                <h4>Privacy Policy</h4>
                <p>
                  At Swiftgofer, operated by Prime Enterprise, we
                  understand the importance of protecting the privacy of our
                  users. This Privacy Policy outlines how we collect, use, and
                  protect your personal information when you use our website,
                  Swiftgofer.com. By accessing or using our website, you agree
                  to the terms of this Privacy Policy.
                </p>
              </div>
            </div>
          </div>
          <div className="policy_main">
            <h4>1. Information Collected</h4>
            <ul className="policy_ul">
              <li>
                <b>Log Files: </b>Our web server uses an extended log file
                format which captures the date and time of your visit, referring
                address (location from which you came to the Swiftgofer
                website), type of Internet browser, and your IP address.
              </li>
              <li>
                <b>Personal Information:</b> Several areas of the Swiftgofer
                website ask for personal information, including the username
                registration, managing your account, and registration pages for
                various online services. The information collected may include
                your name, address, email address, billing information, and
                business profile.
              </li>
            </ul>
          </div>

          <div className="policy_main">
            <h4>2. Use of Information</h4>
            <ul className="policy_ul">
              <li>
                The information collected on our website is used to market and
                improve Swiftgofer’s services, enhance the content and services
                on our website, and make it more user-friendly and relevant to
                individual users.
              </li>
              <li>
                We may use this information to contact you with updates about
                our website or for other marketing purposes, with your
                permission.
              </li>
              <li>
                We never share or sell any information obtained from our website
                to any other organization, except as required by law.
              </li>
            </ul>
          </div>

          <div className="policy_main">
            <h4>3. Registration and Cookies</h4>
            <ul className="policy_ul">
              <li>
                Certain areas of our website, such as 'Tracking', 'Service
                Guide', and 'Corporate Solutions', require registration or a
                password for access. The information obtained from registered
                users may be used for Swiftgofer’s marketing purposes, and
                cookies may be used in these areas to improve the user
                experience.
              </li>
              <li>
                Cookies are used to personalize information for certain segments
                of our customer base and to associate individual customers with
                their information profiles. For example, cookies allow the
                website to remember and auto-fill your user ID when you log in.
              </li>
              <li>
                Cookies can be removed from your hard drive, and you may be able
                to change the properties on your cookie file so that cookies are
                not used or saved, depending on your web browser and version.
              </li>
            </ul>
          </div>

          <div className="policy_main">
            <h4>4. Use of Third-Party Links</h4>
            <ul className="policy_ul">
              <li>
                Parts of the Swiftgofer website may consist of products and
                services hosted by subsidiaries and third-party businesses. When
                you leave our website to visit one of these sites, the only
                information transferred to the third-party is that you came from
                the Swiftgofer website. We are not responsible for the privacy
                practices of third-party sites. You are advised to consult the
                privacy policies at those sites to determine how your
                information may be used.
              </li>
            </ul>
          </div>

          <div className="policy_main">
            <h4>5. Security and Fraud Prevention</h4>
            <ul className="policy_ul">
              <li>
                Swiftgofer takes the security of your information seriously.
                However, be aware of fraudulent emails that may appear to be
                from Swiftgofer, requesting personal or financial information.
                If you receive such emails, do not open them or click on any
                attachments. Delete the email immediately.
              </li>
              <li>
                Common warning signs of online scams include unexpected requests
                for money in return for delivery of a package, requests for
                personal or financial information, links to misspelled or
                altered website addresses, and claims of winning a large sum of
                money.
              </li>
            </ul>
          </div>

          <div className="policy_main">
            <h4>6. Shipment Data</h4>
            <ul className="policy_ul">
              <li>
                Certain shipment data will be provided to the authorities of the
                country of transit or destination for customs and tax clearance
                or for security screening, as required by the laws of such
                country. This information may include shipper and receiver names
                and addresses, description of goods, number of pieces, weight,
                and value of the shipment.
              </li>
            </ul>
          </div>

          <div className="policy_main">
            <h4>Contact Information</h4>
            <p>
              For any queries regarding these terms and conditions, please
              contact us at:
            </p>
            <p>Prime Enterprise</p>
            <p>B-404, JANKI AVENUE, UMELMAN,</p>
            <p>VASAI WEST, VASAI THANE,</p>
            <p>MAHARASHTRA - 401201.</p>
            <p>Email : info@swiftgofer.com </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
