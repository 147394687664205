import React from "react";
import { Icon } from "../../ui";
import { serviceData } from "../home/mock";

const Service = () => {
  return (
    <>
      <div className="container">
        <div className="comman_space">
          <div className="row justify-content-center my-2 pt-5">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>
                  Our <span> Services</span>
                </h4>
                <p>
                  Track your shipment in real-time and stay updated on your
                  delivery status with ease.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-5 text-lg-end text-center">
              <Icon name="serviceImg" className="img-fluid" />
            </div>
            <div className="col-lg-7">
              <div className="commanOther">
                <p>
                  Prime Enterprise offers the best
                  courier services, across India. As one of the INDIA's leading
                  courier company, our bulk buying power enables us to offer
                  huge savings of up to 60% on INDIA parcel delivery ,
                  international courier services, pallet and air freight
                  delivery. We offer industry-leading discounts without any
                  compromise on service excellence. Same business quality
                  service, cheaper prices.
                </p>
                <p>
                  Our parcel comparison site brings together the biggest
                  couriers online, all under one roof. We offer a comprehensive
                  range of economy and expedited shipping options to suit every
                  budget. In many cases, our courier prices are more competitive
                  than standard courier post. However, unlike cheap postage
                  services, Prime Enterprise offer
                  signed for delivery and real time door-to-door courier
                  tracking as standard.
                </p>
              </div>
            </div>
          </div>

          <div className="start_withsub">
            {serviceData?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="start_withbox"
                  // style={{ animationDelay: `${index + 0.1}s` }}
                >
                  <div>
                    <h5>{item?.title}</h5>
                    <p>{item?.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
